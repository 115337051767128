import { Box, Flex, Image, Progress, Spinner, Text, useMediaQuery } from "@chakra-ui/react";
import { format } from "date-fns";
import { useCallback, useState } from "react";
import {
  FaCheck,
  FaCheckDouble,
  FaCloudDownloadAlt,
  FaRegClock,
} from "react-icons/fa";

import { VscError } from "react-icons/vsc";
import { useQuery } from "react-query";
import reactStringReplace from "react-string-replace";
import { apiRoutes } from "../../constants/api-routes";
import { colors } from "../../constants/colors";
import { screenSizes } from "../../constants/screen-sizes";
import useDownloadFile from "../../hooks/useDownloadFile";
import { MessageTemplatesService } from "../../services/message-templates.service";
import { MessagesService } from "../../services/messages.service";
import { MediaType, MessageStatus } from "../../types/Message";
import WhatsappMessageItem from "../WhatsappMessageItem";

const StatusIcon = ({
  status,
  size,
}: {
  status: MessageStatus;
  size: number;
}) => {
  if (status === "sent" || status === "enqueued") {
    return <FaCheck size={size} />;
  }
  if (status === "delivered") {
    return <FaCheckDouble size={size} />;
  }
  if (status === "read") {
    return <FaCheckDouble size={size} color={colors.green} />;
  }
  if (status === "failed" || status === "mismatch") {
    return <VscError size={size} />;
  }

  return <FaRegClock size={size} />;
};

interface MediaItemProps {
  mediaType: MediaType;
  mediaUrl: string;
  onClickSave: (mediaUrl: string) => void;
  fileName: string;
  bgColor?: string;
}

interface UploadProgressProps {
  progress: number;
}

interface DownloadAreaProps {
  bgColor: string;
  text: string;
  onClick: () => void;
}
const DownloadArea = ({ bgColor, text, onClick }: DownloadAreaProps) => {
  return (
    <Flex
      flexDir={"column"}
      bgColor={bgColor}
      borderRadius={5}
      padding={2}
      justify="center"
      align={"center"}
      onClick={onClick}
      cursor="pointer">
      <FaCloudDownloadAlt size={32} />
      {text}
    </Flex>
  );
};

const MediaItem = ({
  mediaUrl,
  onClickSave,
  mediaType,
  fileName,
  bgColor = colors.middleGrey,
}: MediaItemProps) => {
  return mediaType === "image" ? (
    <Box>
      <Image
        src={mediaUrl}
        onClick={() => onClickSave(mediaUrl)}
        cursor="pointer"
        position="relative"
        width={"300px"}
      />
    </Box>
  ) : mediaType === "audio" ? (
    <Flex>
      <audio controls src={mediaUrl}>
        Your browser does not support the
        <code>audio</code> element.
      </audio>
    </Flex>
  ) : (
    <DownloadArea
      bgColor={bgColor}
      onClick={() => onClickSave(mediaUrl)}
      text={fileName}
    />
  );
};



const UploadProgress = ({progress}: UploadProgressProps) => {
  const isDesktop = useMediaQuery(screenSizes.desktop)[0];
  const processing = progress === 100;

  return (
    <Box width="100%" p={4} paddingTop={2} borderRadius="md" bg="white">
      <Flex alignItems="center" justifyContent="space-between" mb={1}>
        <Text fontSize='14px'>{progress > 0 && progress < 100 ? 'enviando mídia ' : 'processando mídia '}...</Text>
      </Flex>
      <Progress value={progress} size='sm' colorScheme={ processing ? 'blue' : 'teal' } borderRadius="md" max={100} isIndeterminate={ !isDesktop || processing } />
    </Box>
  )
};

interface MessageItemProps {
  bgColor: string;
  status: MessageStatus;
  text: string;
  textColor: string;
  createdAt: string;
  isFromUser: boolean;
  mediaType?: MediaType | null;
  mediaId?: string | null;
  fileKey?: string | null;
  mediaUrl?: string | null;
  messageTemplateId?: string | null;
  uploadProgress?: number;
}

const MessageItem = ({
  bgColor,
  status,
  text,
  textColor,
  createdAt,
  isFromUser,
  mediaType,
  mediaId,
  fileKey,
  mediaUrl: initialMediaUrl,
  messageTemplateId,
  uploadProgress=0,
}: MessageItemProps) => {
  const [mediaUrl, setMediaUrl] = useState(initialMediaUrl);
  const { downloadFileFromUrl } = useDownloadFile();
  const messageTemplates = useQuery(
    apiRoutes.listMessageTemplates(),
    async () => {
      const { data } = await MessageTemplatesService.listMessageTemplates();
      return data;
    },
    {
      staleTime: 1000 * 60 * 5,
      enabled: !messageTemplateId,
    }
  );
  const messageTemplate = messageTemplates.data?.find(
    (template) => template.id === messageTemplateId
  );

  const handleDownload = useCallback(() => {
    if (mediaUrl) return;
    MessagesService.downloadMedia({
      mediaId: mediaId!,
      mediaName: text,
      fileKey: fileKey!,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));

      const haveMediaItemComponent = ["image", "audio"].includes(mediaType!);
      if (haveMediaItemComponent) {
        setMediaUrl(url);
      } else {
        downloadFileFromUrl(url, text);
      }
    });
  }, [downloadFileFromUrl, fileKey, mediaId, mediaType, mediaUrl, text]);

  return (
    <Flex
      maxWidth="75%"
      padding={2}
      background={bgColor}
      color={textColor}
      borderRadius="15px"
      alignItems="flex-end"
      flexDirection={messageTemplate ? 'column' : 'row'}
      position="relative"
      gap={messageTemplate ? 2 : 0}
    >
      { uploadProgress > 0 ? (
        <UploadProgress progress={uploadProgress} />
      ) : messageTemplate ? (
        <WhatsappMessageItem
          fileUrl={messageTemplate.mediaUrl}
          buttons={messageTemplate.messageTemplateButtons}
          footer={messageTemplate.footerText}
          message={text}
        />
      ) : mediaId || fileKey || mediaUrl ? (
        mediaUrl && mediaType ? (
          <MediaItem
            bgColor={"white"}
            mediaType={mediaType}
            mediaUrl={mediaUrl}
            onClickSave={() => downloadFileFromUrl(mediaUrl, text)}
            fileName={text}
          />
        ) : (
          <DownloadArea
            bgColor={"white"}
            onClick={handleDownload}
            text={text}
          />
        )
      ) : (
        <Text maxWidth={"100%"} whiteSpace="pre-line">
          {reactStringReplace(text, /\*(.*?)\*/, (match, i) => (
            <Text as="span" fontWeight="bold" key={i}>
              {match}
            </Text>
          ))}
          {Array(16).fill('\u00A0').join('')}
        </Text>
      )}
      <Flex
        justifyContent="flex-end"
        alignItems="center"
        gap={1}
        position={messageTemplate ? "relative" : "absolute"}
        right={messageTemplate ? '0px' : 2}
        bottom={messageTemplate ? '0px' : 2}
      >
        <Text fontSize={8}>{format(new Date(createdAt), "dd/MM - HH:mm")}</Text>
        {isFromUser && <StatusIcon status={status} size={8} />}
      </Flex>
    </Flex>
  );
};

export default MessageItem;
