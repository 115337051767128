import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, IconButton } from '@chakra-ui/react';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import { BsFillEmojiSmileFill } from 'react-icons/bs';
import ButtonIcon from '../ButtonIcon';

type CustomEmojiPickerRenderAs = "ButtonIcon" | "IconButton";
type CustomEmojiPickerAnchorPosition = "top" | "bottom";

const DEFAULT_CUSTOM_EMOJI_PICKER_ICON: React.ReactElement = (
  <BsFillEmojiSmileFill />
);
const DEFAULT_CUSTOM_EMOJI_PICKER_BUTTON_COMPONENT: CustomEmojiPickerRenderAs =
  "ButtonIcon";
const DEFAULT_EMOJI_PICKER_ANCHOR_POSITION: CustomEmojiPickerAnchorPosition =
  "bottom";
const DEFAULT_EMOJI_PICKER_HEIGHT = 400;
const DEFAULT_EMOJI_PICKER_GAP = 20;

interface CustomEmojiPickerProps {
  Icon?: React.ReactElement;
  renderAs?: CustomEmojiPickerRenderAs;
  emojiPickerHeight?: number;
  emojiPickerGap?: number;
  emojiPickerAnchorPosition?: CustomEmojiPickerAnchorPosition;
  onEmojiSelection: (emoji: string) => void;
  closeAfterPicking?: boolean;
}

const CustomEmojiPicker: React.FC<CustomEmojiPickerProps> = ({
  Icon = DEFAULT_CUSTOM_EMOJI_PICKER_ICON,
  renderAs = DEFAULT_CUSTOM_EMOJI_PICKER_BUTTON_COMPONENT,
  emojiPickerHeight = DEFAULT_EMOJI_PICKER_HEIGHT,
  emojiPickerGap = DEFAULT_EMOJI_PICKER_GAP,
  emojiPickerAnchorPosition = DEFAULT_EMOJI_PICKER_ANCHOR_POSITION,
  onEmojiSelection,
  closeAfterPicking = true,
}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef<HTMLDivElement>(null);
  const emojiIconRef = useRef<HTMLButtonElement>(null);

  const emojiPickerAnchorProps: Pick<
    React.ComponentProps<typeof Box>,
    CustomEmojiPickerAnchorPosition
  > = useMemo(() => {
    if (emojiPickerAnchorPosition === "top") {
      return {top: `-${emojiPickerHeight + emojiPickerGap}px`};
    }

    return {top: `${DEFAULT_EMOJI_PICKER_GAP}px`};
  }, [emojiPickerAnchorPosition, emojiPickerGap, emojiPickerHeight]);

  const handleOnEmojiClick = (emojiData: EmojiClickData) => {
    onEmojiSelection(emojiData.emoji);
    if (closeAfterPicking) setShowEmojiPicker(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!emojiPickerRef.current?.contains(event.target as Node) &&
        !emojiIconRef.current?.contains(event.target as Node)) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {renderAs === "ButtonIcon" ? (
        <ButtonIcon
          ref={emojiIconRef}
          aria-label="Adicionar emoji"
          icon={Icon}
          onClick={() => setShowEmojiPicker(!showEmojiPicker)}
        />
      ) : (
        <IconButton
          ref={emojiIconRef}
          aria-label="Adicionar emoji"
          variant="ghost"
          icon={Icon}
          onClick={() => setShowEmojiPicker(!showEmojiPicker)}
        />
      )}
      {showEmojiPicker && (
        <Box
          {...emojiPickerAnchorProps}
          position="absolute"
          zIndex={1}
          ref={emojiPickerRef}
        >
          <EmojiPicker
            onEmojiClick={handleOnEmojiClick}
            height={DEFAULT_EMOJI_PICKER_HEIGHT}
          />
        </Box>
      )}
    </>
  );
};

export default CustomEmojiPicker;
