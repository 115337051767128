import { Avatar, Box, Flex, Text, useMediaQuery } from "@chakra-ui/react";
import { differenceInMinutes } from "date-fns";
import { useSelector } from "react-redux";
import { colors } from "../../../../constants/colors";
import { isUnreadConversation } from "../../../../state/inboxSlice";
import { RootState } from "../../../../state/store";
import { Message } from "../../../../types/Message";
import { screenSizes } from "../../../../constants/screen-sizes";
import { User } from "../../../../types/Prisma";
import { BsPersonFill } from "react-icons/bs";

interface ConversationItemProps {
  name: string;
  isActive: boolean;
  message?: Pick<Message, "createdAt" | "fromSystem" | "text" | "status">;
  onClick: () => void;
  conversationId: string;
  companyAgents: User[];
}

const ConversationItem = ({
  name,
  isActive,
  message,
  onClick,
  conversationId,
  companyAgents,
}: ConversationItemProps) => {
  const isNotRead = useSelector((state: RootState) =>
    isUnreadConversation(state, conversationId)
  );
  const agentId = useSelector((state: RootState) =>
    state.inbox.conversations.find((conversation) => conversation.id === conversationId)?.ticketAgentId
  );
  const agent = companyAgents.find((agent) => agent.id === agentId);
  const minutesSinceStart = message
    ? differenceInMinutes(new Date(), new Date(message.createdAt))
    : 0;
  const hoursSinceStart = Math.floor(minutesSinceStart / 60);
  const daysSinceStart = Math.floor(hoursSinceStart / 24);

  const isMobile = useMediaQuery(screenSizes.mobile)[0];

  function getColor(minutesSinceStart: number) {
    if (minutesSinceStart < 30) {
      return "green";
    }
    if (minutesSinceStart < 6 * 60) {
      return "orange";
    }
    if (minutesSinceStart < 23 * 60) {
      return "red";
    }
    return "gray";
  }

  return (
    <Flex
      padding={3}
      justifyContent="space-between"
      borderRadius={5}
      bgColor={isActive ? colors.lightGrey : "transparent"}
      color={isNotRead ? colors.black : colors.darkGrey}
      fontWeight={isNotRead ? "medium" : "normal"}
      onClick={onClick}
      _hover={{
        bgColor: colors.lightGrey,
        cursor: "pointer",
      }}>
      <Flex gap={2}>
        <Avatar name={name} size={"sm"} />
        <Box>
          <Text textOverflow={"ellipsis"} noOfLines={1} width="160px">
            {name}
          </Text>
          <Text
            fontSize="sm"
            textOverflow={"ellipsis"}
            noOfLines={1}
            width="160px">
            {message?.fromSystem ? "Você: " : null}
            {message?.text}
          </Text>
        </Box>
      </Flex>
      <Flex direction="column" justifyContent="flex-end" alignItems={"end"} gap={1}>
        { isMobile && (
          <Text fontSize={12} display={"flex"} gap={1} alignItems={"center"}>
            { agent?.name && (
              <>
                <BsPersonFill />
                {agent.name}
              </>
            )}
          </Text>
        )}
        <Text fontSize="sm" color={getColor(minutesSinceStart)}>
          {minutesSinceStart === 0 ? 'agora' : minutesSinceStart < 60
            ? `${minutesSinceStart} min`
            : hoursSinceStart < 24
            ? `${hoursSinceStart} h`
            : `${daysSinceStart} d`}
        </Text>
      </Flex>
    </Flex>
  );
};

export default ConversationItem;
