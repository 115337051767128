import {
  Button,
  FormControl,
  FormLabel,
  Input, Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  VStack
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BsBagCheck } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { apiRoutes } from "../../../../constants/api-routes";
import { CompaniesService } from "../../../../services/companies.service";
import {
  CustomersService,
  UploadCustomersDto
} from "../../../../services/customers.service";
import { MixpanelService } from "../../../../services/mixpanel.service";
import {
  OrdersService,
  UploadOrdersDto
} from "../../../../services/orders.service";
import { updateRefetchKey } from "../../../../state/campaignCreationSlice";
import {
  CompanyDefinedField,
  CompanyDefinedFieldTableEnum
} from "../../../../types/CompanyDefinedField";
import { CustomerUploadHeadersEnum } from "../../../../types/CustomerUploadHeadersEnum";
import { FileUtils } from "../../../../utils/file-utils";

interface UploadFileModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const UploadFileModal = ({ isOpen, onClose }: UploadFileModalProps) => {
  const dispatch = useDispatch();
  const [headerOptions, setHeaderOptions] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadType, setUploadType] = useState<null | "customers" | "orders">(
    null
  );
  const [mappedHeaders, setMappedHeaders] = useState<{
    [key in CustomerUploadHeadersEnum]?: string;
  }>({});
  const uploadCustomers = useMutation(
    (uploadCustomersDto: UploadCustomersDto) =>
      CustomersService.uploadCustomers(uploadCustomersDto),
    {
      onSuccess: () => {
        MixpanelService.track("import-customers");
        dispatch(updateRefetchKey())
        handleClose();
      },
    }
  );
  const uploadOrders = useMutation(
    (uploadOrdersDto: UploadOrdersDto) =>
      OrdersService.uploadOrders(uploadOrdersDto),
    {
      onSuccess: () => {
        MixpanelService.track("import-orders");
        dispatch(updateRefetchKey())
        handleClose();
      },
    }
  );
  const { data: fieldsToMap = [], refetch } = useQuery(
    apiRoutes.listCompanyDefinedFields(CompanyDefinedFieldTableEnum.CUSTOMERS),
    async () => {
      const res = await CompaniesService.listCompanyDefinedFields(
        CompanyDefinedFieldTableEnum.CUSTOMERS
      );
      return res.data;
    },
    {
      select(data) {
        const customFields = data.map((field: CompanyDefinedField) => ({
          label: field.name,
          value: field.name,
          isRequired: false,
        }));
        return [
          {
            label: "Nome",
            value: CustomerUploadHeadersEnum.NAME,
            isRequired: true,
          },
          {
            label: "Telefone",
            value: CustomerUploadHeadersEnum.PHONE_NUMBER,
            isRequired: true,
          },
          {
            label: "Email",
            value: CustomerUploadHeadersEnum.EMAIL,
            isRequired: false,
          },
          {
            label: "Data de nascimento",
            value: CustomerUploadHeadersEnum.BIRTH_DATE,
            isRequired: false,
          },
          {
            label: "Código do cliente",
            value: CustomerUploadHeadersEnum.SOURCE_ID,
            isRequired: false,
          },
          ...customFields,
        ];
      },
    }
  );

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen, refetch]);

  async function handleChangeFile(e: any) {
    const file: File = e.target.files[0];
    setSelectedFile(file);

    if (uploadType === "customers") {
      const headers: string[] = await FileUtils.getHeadersFromSheet(file);
      setHeaderOptions(headers);
    }
  }

  function handleClose() {
    setHeaderOptions([]);
    setSelectedFile(null);
    setMappedHeaders({});
    setUploadType(null);
    onClose();
  }

  function handleChangeHeader(e: any) {
    const { value, name } = e.target;
    setMappedHeaders((prev) => ({
      ...prev,
      [name]: value || undefined,
    }));
  }

  async function handleSubmit() {
    if (!selectedFile) return;
    if (uploadType === "customers") {
      await uploadCustomers.mutateAsync({
        file: selectedFile as File,
        mappedHeaders,
      });
    } else if (uploadType === "orders") {
      await uploadOrders.mutateAsync({
        file: selectedFile as File,
      });
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {uploadType === null
            ? "Importação de arquivo"
            : uploadType === "customers"
            ? "Importação de clientes"
            : "Importação de histórico de vendas"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {uploadType === null ? (
            <VStack spacing={4}>
              <Button
                width="200px"
                onClick={() => setUploadType("customers")}
                leftIcon={<FaUsers size="24px" />}>
                Dados cadastrais
              </Button>
              <Button
                width="200px"
                onClick={() => setUploadType("orders")}
                leftIcon={<BsBagCheck size="24px" />}>
                Histórico de vendas
              </Button>
            </VStack>
          ) : !selectedFile ? (
            <VStack spacing={4}>
              <Text mb={4}>Selecione o arquivo</Text>
              <input
                type="file"
                onChange={handleChangeFile}
                accept={
                  "text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                }
              />
            </VStack>
          ) : uploadType === "customers" ? (
            <VStack>
              <Text mb={4}>
                Associe as colunas correspondentes para importação
              </Text>
              {fieldsToMap.map((field) => (
                <FormControl
                  display={"flex"}
                  alignItems="center"
                  justifyContent={"space-between"}
                  key={field.value}
                  isRequired={field.isRequired}>
                  <FormLabel w="30%">{field.label}</FormLabel>
                  <Select
                    placeholder="Selecione uma coluna"
                    onChange={handleChangeHeader}
                    name={field.value}>
                    {headerOptions?.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              ))}
              <FormControl
                display={"flex"}
                alignItems="center"
                justifyContent={"space-between"}
                key={CustomerUploadHeadersEnum.TAGS}>
                <FormLabel w="30%">TAG</FormLabel>
                <Input
                  key={CustomerUploadHeadersEnum.TAGS}
                  placeholder="Adicionar tag"
                  onChange={(e) =>
                    setMappedHeaders((prev) => ({
                      ...prev,
                      [CustomerUploadHeadersEnum.TAGS]:
                        e.target.value?.trim() || undefined,
                    }))
                  }
                />
              </FormControl>
            </VStack>
          ) : (
            <VStack>
              <Text mb={4}>
                Ao importar pedidos, os clientes associados serão criados
                automaticamente, caso ainda não estejam cadastrados.
              </Text>
            </VStack>
          )}
        </ModalBody>
        <ModalFooter display={"flex"} justifyContent="space-between">
          <div />
          {selectedFile && (
            <Button
              onClick={() => handleSubmit()}
              isLoading={uploadCustomers.isLoading || uploadOrders.isLoading}>
              Importar {uploadType === "customers" ? "clientes" : "pedidos"}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
